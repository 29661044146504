











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AccordionItem extends Vue {
    @Prop() private title!: string
}
