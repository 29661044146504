
import { Component, Vue } from 'vue-property-decorator'
import { mapActions } from 'vuex'
import axios from 'axios'
import FilingNotAvailable from '@/components/checkout/filing/FilingNotAvailable.vue'
import FilingProcessingInfo from '@/components/checkout/filing/FilingProcessingInfo.vue'
import FilingTypes from '@/components/checkout/filing/FilingTypes.vue'

@Component({ components: { FilingNotAvailable, FilingProcessingInfo, FilingTypes } })
export default class FilingTaxCase extends Vue {
  private elsterAvailable = false
  private isSentViaElster = false
  private dialog = false

  methods() {
    mapActions(['elsterFiling'])
  }

  async created() {
    await this.isElsterAvailable()
    await this.isCaseSentViaElster()

    if (this.isSentViaElster) {
      await this.$store.dispatch('updateCurrentStep', 'checkout-filing-transfer-data')
    }
  }

  doElsterFiling() {
    this.$store.dispatch('elsterFiling').finally(
      () => {
        this.dialog = false
        this.$store.dispatch('updateCurrentStep', 'checkout-filing-info')
        this.$router.replace({ name: 'checkout-filing-info' })
      },
    )
  }

  async isElsterAvailable() {
    await axios.get('/elster/isElsterAvailable').then(result => {
      // @ts-ignore
      this.elsterAvailable = result.data
    })
  }

  async isCaseSentViaElster() {
    await axios.get('/elster/isSentViaElster').then(result => {
      this.isSentViaElster = result.data
    })
  }
}
