
import { Component, Prop, Vue } from 'vue-property-decorator'
import DownloadButton from '@/components/checkout/base/DownloadButton.vue'
import axios from 'axios'

@Component({
  components: { DownloadButton }
})
export default class SendReceipts extends Vue {
  @Prop({ default: () => [] }) private tokenName: any | undefined
  @Prop({ default: () => [] }) private csrfToken: any | undefined
  @Prop({ default: () => [] }) private stateInfo: any | undefined

  private sent = false // documentsSent from filingState

  created() {
    this.sent = this.stateInfo.documentsSent || this.stateInfo.receiptsOk
    this.$store.state.nextSectionEnabled = this.sent
  }

  updated() {
    if (this.sent) {
      this.setDocumentsSent(this.sent)
    }

    this.$store.state.nextSectionEnabled = this.sent
  }

  setDocumentsSent(sent: boolean) {
    axios.post('/elster/saveInterviewSessionParam?sent=' + (sent ? 'true' : 'false'), {}, {})
      // eslint-disable-next-line no-console
      .catch(errors => console.warn(errors))
  }
}
