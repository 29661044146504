
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({
      currentFilingStepIndex: 'getCurrentFilingStepIndex',
      checkoutSteps: 'getCheckoutSteps'
    })
  }
})
export default class CheckoutAppHeader extends Vue {
  private checkoutSteps!: any[]
  private countedSteps: number | undefined = this.checkoutSteps ? this.checkoutSteps.length : 0

  mounted() {
    this.countedSteps = this.$store.state.checkoutSteps?.length
  }

  updated() {
    this.countedSteps = this.checkoutSteps.length
  }
}
