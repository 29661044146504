export default {
  methods: {
    caseTypeLabel(caseType: any) {
      switch (caseType) {
        case 'EST':
          return 'Einkommensteuererklärung'
        case 'EÜR':
        case 'EUER':
          return 'Einnahmenüberschussrechnung'
        case 'EÜR_PV':
        case 'EUER_PV':
          return 'Einnahmenüberschussrechnung Photovoltaik'
        case 'GES':
          return 'Gesonderte Feststellung'
        case 'GES_E':
          return 'Gesonderte und einheitliche Feststellung'
        case 'GES_V':
          return 'Vermietung (Feststellungserklärung)'
        case 'GEWST':
          return 'Gewerbesteuererklärung'
        case 'UST':
          return 'Umsatzsteuererklärung'
        case 'UST_GES_AND_GES_E':
          return 'Umsatzsteuererklärung (Feststellungserklärung)'
      }
    }
  }
}
