
import { Vue, Component, Prop } from 'vue-property-decorator'
import DashboardInfoDialog from '@/components/case-list/DashboardInfoDialog.vue'

@Component({
  components: { DashboardInfoDialog }
})
export default class DashboardServiceTile extends Vue {
  @Prop() serviceUrl!: string
  @Prop() tileIcon!: string
  @Prop() iconAltText!: string
  @Prop() tileText!: string
  @Prop() tileButtonText!: string
  @Prop() tileButtonClass!: string
  @Prop() confirmButtonText!: string

  private dialogOpen = false
  private tileButtonCssClasses = `btn btn-${this.tileButtonClass}`

  updateDialogStatus(showInfo: boolean) {
    this.dialogOpen = showInfo
  }
}
