
import { Component, Vue } from 'vue-property-decorator'
import ElsterTransferTicket from '@/components/checkout/filing/ElsterTransferTicket.vue'
import axios from 'axios'

@Component({ components: { ElsterTransferTicket } })
export default class FilingElsterTickets extends Vue {
  private elsterTickets: any[] = []
  private ticketsGroupedByPerson: any = {
    personAAndB: {
      name: '',
      id: '',
      tickets: []
    },
    personA: {
      name: '',
      id: '',
      tickets: []
    },
    personB: {
      name: '',
      id: '',
      tickets: []
    },
    personUnknown: {
      name: '',
      id: '',
      tickets: []
    }
  }

  async created() {
    await this.$store.dispatch('updateCurrentStep', '')

    await this.getElsterTickets()
    this.groupTicketsByPerson()
  }

  async getElsterTickets() {
    await axios.get('/elster/getElsterTransferData').then(result => {
      this.elsterTickets = result.data
    })
  }

  groupTicketsByPerson() {
    let personAName: string
    this.elsterTickets.forEach((ticket) => {
      if (!personAName) {
        personAName = ticket.transferData.personA.name
      }
      if (ticket.transferData.personB?.name) {
        this.ticketsGroupedByPerson.personAAndB.tickets.push(ticket)
        this.ticketsGroupedByPerson.personAAndB.name = ticket.transferData.personA.name + ' & ' + ticket.transferData.personB.name
      } else {
        if (!ticket.transferData.personA.name) {
          this.ticketsGroupedByPerson.personUnknown.tickets.push(ticket)
        } else if (ticket.transferData.personA.name === personAName) {
          this.ticketsGroupedByPerson.personA.tickets.push(ticket)
          this.ticketsGroupedByPerson.personA.name = ticket.transferData.personA.name
        } else {
          this.ticketsGroupedByPerson.personB.tickets.push(ticket)
          this.ticketsGroupedByPerson.personB.name = ticket.transferData.personA.name
        }
      }
    })
  }
}
