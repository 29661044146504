
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  computed: {},
})
export default class NavigationButtons extends Vue {
  @Prop() private nextButtonId: any | undefined

  goBack() {
    window.history.back()
  }
}
