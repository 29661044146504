import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/src/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de'
  },
  icons: {
    iconfont: 'fa',
    values: {
      prev: 'fas fa-angle-left',
      next: 'fas fa-angle-right',
      expand: 'fas fa-angle-down',
      refresh: 'fa fa-refresh',
      check: 'fa fa-check',
      waiting: 'fa-regular fa-exclamation',
      xMark: 'fa fa-times',
      loading: 'fas fa-circle-notch fa-spin',
      idCard: 'fa fa-id-card',
      info: 'fa fa-info-circles',
      questionMark: 'fas fa-question-circle',
    }
  },
  options: {
    customProperties: true
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#5F6FFF',
        primaryBtnInactive: '#C0C9FF',
        secondary: '#B0ACAE',
        neutral: '#757379',
        neutralLight: '#F9F7F6',
        success: '#00C38A',
        successLight: '#C6FFE6',
        inProgress: '#F3A102',
        inProgressLight: '#FFEDD2',
        failure: '#FF3A57',
        failureLight: '#FFD9DD',
        borderGreen: '#43FAC0',
        backgroundGreen: '#C6FFE6',
        backgroundWhite: '#FDFCFC',
        grayLight: '#DADADA',
        blueDark: '#1C1C37',

      }
    }
  }
})
