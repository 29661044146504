import { render, staticRenderFns } from "./PreviewApp.vue?vue&type=template&id=2f90299e&scoped=true&"
import script from "./PreviewApp.vue?vue&type=script&lang=ts&"
export * from "./PreviewApp.vue?vue&type=script&lang=ts&"
import style0 from "./PreviewApp.vue?vue&type=style&index=0&id=2f90299e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f90299e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VCard,VCardActions,VCardText,VCardTitle,VCol,VDivider,VMain,VRow})
