import axios from 'axios'
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { CreateElement, VNode } from 'vue/types'
import PromiseFrame from '@/components/frames/PromiseFrame'

@Component
export default class LoadingFrame extends Vue {
  @Prop() private url: string | undefined

  private api = axios.create()

  private response: Promise<{ [k: string]: string }> | null = null

  @Watch('url', { immediate: true })
  async onUrlChanged(url: string | null) {
    if (url == null) {
      return
    }
    this.response = this.api.get(url).then(
      (response) => response.data)
  };

  render?(h: CreateElement): VNode {
    return h(PromiseFrame, {
      props: { promise: this.response },
      scopedSlots: {
        default: (props) => {
          return this.$scopedSlots.default?.(props)
        }
      }
    })
  }
}
