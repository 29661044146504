
import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import DownloadDocuments from '@/components/filing-documents/documents/print/DownloadDocuments.vue'

@Component({ components: { DownloadDocuments } })
export default class NothingToSend extends Vue {
  @Prop({ default: () => [] }) private tokenName: any | undefined
  @Prop({ default: () => [] }) private csrfToken: any | undefined

  created() {
    this.setDocumentsPrinted(true)
  }

  setDocumentsPrinted(printed: boolean) {
    axios.post('/elster/saveInterviewSessionParam?printed=' + (printed ? '1' : '0'), {}, {})
      // eslint-disable-next-line no-console
      .catch(errors => console.warn(errors))
  }
}
