<script>
import axios from 'axios'
import { DateTime } from 'luxon'
import { load } from '@finapi/web-form'
import Alert from '@/components/checkout/base/Alert.vue'

const VerificationStatus = Object.freeze({
  IN_PROGRESS: {
    status: 'IN_PROGRESS',
    icon: 'waiting',
    alertType: 'warning',
    statusTitel: 'Verifizierung läuft',
    statusText:
            'Wir haben Ihre Anfrage an finAPI weitergeleitet. \n' +
            'Den Status der Online-Identifizierung können Sie über »Aktualisieren« prüfen. '
  },
  NOT_STARTED: {
    status: 'NOT_STARTED',
    icon: 'waiting',
    alertType: 'warning',
    statusTitel: 'Verifizierung noch nicht gestartet',
    statusText:
            'finAPI übermittelt das Ergebnis der Prüfung an smartsteuer. \n' +
            'Bitte haben Sie einen Moment Geduld.\n' +
            'Falls Sie nicht automatisch zum Ergebnis der Prüfung weitergeleitet werden, klicken Sie bitte »Aktualisieren«.'
  },
  COMPLETED: {
    status: 'COMPLETED',
    icon: 'check',
    alertType: 'success',
    statusTitel: 'Verifizierung erfolgreich',
    statusText:
            'Ihre Online-Identifizierung über finAPI war erfolgreich. \n' +
            'Die Abgabe bei smartsteuer ist freigeschaltet. \n \n' +
            'Sie werden in 15 Sekunden automatisch zur Übermittlung weitergeleitet.'
  },
  FAILURE: {
    status: 'FAILURE',
    icon: 'xMark',
    alertType: 'error',
    statusTitel: 'Verifizierung gescheitert',
    statusText:
            'Ihre Online-Identifizierung war nicht erfolgreich.\n' +
            'Bitte prüfen Sie die erfassten Daten und starten die Online-Identifizierung erneut.\n ' +
            'Bitte wenden Sie sich gegebenenfalls zu Ihrer Unterstützung über »Support« an das smartsteuer-Team.\n'
  },
  ERROR: {
    status: 'ERROR',
    icon: 'xMark',
    alertType: 'error',
    statusTitel: 'Fehler bei der Verifizierung',
    statusText:
            'Es ist ein Fehler aufgetreten. \n' +
            'Bitte versuchen Sie es später nochmal. \n' +
            'Bitte wenden Sie sich gegebenenfalls zu Ihrer Unterstützung über »Support« an das smartsteuer-Team.\n'
  },
  COMPLETED_WITH_ERROR: {
    status: 'ERROR',
    icon: 'xMark',
    alertType: 'error',
    statusTitel: 'Verifizierungsvorgang wurde mit Fehler abgeschlossen',
    statusText:
            'Es ist ein Fehler aufgetreten. \n' +
            'Bitte versuchen Sie es später nochmal. \n' +
            'Bitte wenden Sie sich gegebenenfalls zu Ihrer Unterstützung über »Support« an das smartsteuer-Team.\n'
  }
})

export default {
  components: {
    Alert
  },
  props: {
    reloadUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      finApiTargetEnv: this.$store.state.verificationData.verificationMethods.find(
        vm => vm.name === 'finApi'
      ).url,
      menu: false,
      valid: true,
      stepCounter: 1,
      items: [
        { abbr: 'M', label: 'Männlich' },
        { abbr: 'W', label: 'Weiblich' },
        { abbr: 'D', label: 'Divers' }
      ],
      verificationStatus: VerificationStatus.NOT_STARTED,
      finApiFormUrl: '#',
      errorStatus: undefined,
      loadingSubmit: false,
      loadingRefresh: false,
      dialog: false,
      intervalId: null,
      finApiWizard: {
        firstName: '',
        lastName: '',
        username: '',
        title: '',
        iban: '',
        gender: '',
        dateOfBirth: '1980-01-01',
        street: '',
        zip: '',
        city: ''
      },
      ibanRules: [
        v => !!v || 'Bitte füllen Sie dieses Feld aus',
        v =>
          v.length <= 64 ||
                    'Die IBAN darf nicht länger als 64 Zeichen sein.',
        v =>
          this.handleIbanFieldErrors() === '' ||
                    this.handleIbanFieldErrors()
      ],
      birthDateRule: [
        v => !!v || 'Bitte füllen Sie dieses Feld aus',
        v =>
          DateTime.fromFormat(v, 'dd.MM.yyyy').isValid ||
                    'Das eingetragene Datum hat ein falsches Format. (01.01.1970)'
      ],
      notNullRule: v => !!v || 'Bitte füllen Sie dieses Feld aus.',
      firstNameRule: v =>
        /^\D+$/.test(v) || 'Bitte benutzen Sie keine Ziffern.',
      lastNameRule: [
        v =>
          /.*[a-zA-Z].*/.test(v) ||
                    'Der Nachname enthält ungültige Zeichen.',
        v =>
          v.length >= 2 ||
                    'Der Nachname soll nicht kürzer als 2 Zeichen sein.',
        v =>
          v.length <= 46 ||
                    'Der Nachname soll nicht länger als 46 Zeichen sein.'
      ]
    }
  },
  computed: {
    formatDateGer() {
      return (
        this.finApiWizard.dateOfBirth &&
                DateTime.fromISO(this.finApiWizard.dateOfBirth).toFormat(
                  'dd.MM.yyyy'
                )
      )
    }
  },
  mounted() {
    this.prefill()
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  methods: {
    async prefill() {
      await axios.get('/api/prefill-finApi-summary').then(res => {
        this.finApiWizard = res.data
        // Gender comes as null when case is imported(clean case bug). Nulls lead the form to be validated as false from beginning
        if (this.finApiWizard.gender == null) {
          this.finApiWizard.gender = ''
        }
      })
    },
    handleIbanFieldErrors() {
      if (this.errorStatus !== undefined && this.errorStatus.length > 0) {
        const error = this.errorStatus.find(
          e =>
            e.errorType === 'VERIFICATION_ERROR_INVALID_IBAN' ||
                        e.errorType === 'BANK_NOT_FOUND'
        )
        if (error) {
          return error.errorMessage
        }
      }
      return ''
    },
    submit() {
      this.$refs.form.validate()
      if (this.valid) {
        this.loadingSubmit = true
      }
      axios
        .post('/api/verify-with-finApi', this.finApiWizard)
        .then(res => {
          this.dialog = true
          const formId = res.data.redirectUrl.split('/').pop()
          this.createWebForm(formId)
        })
        .catch(err => {
          if (
            (!err.response.data['verification-error'] ||
                            err.response.data['verification-error'].find(
                              e =>
                                e.errorType === 'UNKNOWN' ||
                                    e.errorType === 'INPUT_VALIDATION_ERROR'
                            )) &&
                        err.request.status >= 400
          ) {
            this.verificationStatus = VerificationStatus.ERROR
            this.stepCounter = 2
          } else {
            this.errorStatus =
                            err.response.data['verification-error']
          }

          this.$refs.form.validate()
        })
        .finally(() => {
          this.loadingSubmit = false
        })
    },
    fillDemoData() {
      this.finApiWizard.firstName = 'Finapi'
      this.finApiWizard.lastName = 'Demobank'
      this.finApiWizard.dateOfBirth = '1980-01-01'
      this.finApiWizard.gender = 'M'
      this.finApiWizard.title = ''
      this.finApiWizard.iban = 'DE77533700080111111100'
      this.finApiWizard.street = 'Adams-Lehmann-Str. 44'
      this.finApiWizard.zip = '80797'
      this.finApiWizard.city = 'München'
    },
    deleteVerification() {
      axios.delete('/api/clear-verification', { data: this.finApiWizard })
    },
    refreshVerificationState() {
      this.loadingRefresh = true

      return axios
        .post('/api/update-finApi-result', this.finApiWizard)
        .then(res => {
          const status = res.data.status
          if (status.toString() === 'COMPLETED') {
            clearInterval(this.intervalId)
            if (!res.data.successfullyVerified) {
              this.verificationStatus = VerificationStatus.FAILURE
            } else {
              this.verificationStatus = VerificationStatus[status]
              this.goToFilingTimeoutId = setTimeout(
                () => this.goToFiling(),
                15 * 1000
              )
            }
          } else {
            this.verificationStatus = VerificationStatus[status]
          }
        })
        .catch(() => {
          clearInterval(this.intervalId)
        })
        .finally(() => {
          this.loadingRefresh = false
        })
    },
    goToFiling() {
      clearTimeout(this.goToFilingTimeoutId)
      this.$router.replace({ name: 'checkout-filing' })
    },
    resetWizard() {
      this.verificationStatus = VerificationStatus.NOT_STARTED
      this.stepCounter = 1
    },
    resetValidation() {
      this.errorStatus = { errorType: 'NONE', errorMessage: '' }
      this.$refs.form.resetValidation()
    },
    createWebForm(formId) {
      this.verificationStatus = VerificationStatus.IN_PROGRESS
      const closeDialogAndMoveToNextStep = () => {
        this.dialog = false
        this.stepCounter = 2
      }

      const closeDialogAndMoveToStart = () => {
        this.dialog = false
        this.stepCounter = 1
        this.verificationStatus = VerificationStatus.NOT_STARTED
        this.deleteVerification()
        clearInterval(this.intervalId)
      }

      const updatePollingInterval = () => {
        this.intervalId = setInterval(
          () => this.refreshVerificationState(),
          15 * 1000
        )
        setTimeout(() => {
          clearInterval(this.intervalId)
        }, 15 * 60 * 1000)
      }

      const updateVerificationStatusOnLoadError = () => {
        this.verificationStatus = VerificationStatus.FAILURE
        clearInterval(this.intervalId)
      }
      load(
        document.querySelector('#webFormContainer'),
        { token: formId, targetEnvironment: this.finApiTargetEnv },
        {
          onComplete: () => {
            closeDialogAndMoveToNextStep()
            this.refreshVerificationState().then(() => {
              if (this.verificationStatus !== VerificationStatus.COMPLETED) {
                updatePollingInterval()
              }
            })
          },
          onFail: function () {
            closeDialogAndMoveToStart()
          },
          onAbort: function () {
            closeDialogAndMoveToStart()
          },
          onLoadError: function () {
            updateVerificationStatusOnLoadError()
          }
        }
      )
    }
  }
}
</script>

<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-main>
    <v-stepper
      id="finApiVerification"
      v-model="stepCounter"
    >
      <v-stepper-header id="finApiStepperHeader">
        <v-stepper-step
          :complete="stepCounter > 1"
          step="1"
          style="margin: 0 10px 0 10px"
        >
          Überprüfung der Daten
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="stepCounter > 2"
          step="2"
          style="margin: 0 10px 0 10px"
        >
          Ergebnis der Online-Identifizierung
        </v-stepper-step>
      </v-stepper-header>
      <v-divider class="mx-10" />
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
          >
            <v-row>
              <v-col
                align-center
                cols="4"
              >
                <label for="finApiFirstname">Vorname*</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiFirstname"
                  v-model="finApiWizard.firstName"
                  :rules="[notNullRule, firstNameRule]"
                  dense
                  hide-details="auto"
                  name="firstName"
                  outlined
                  single-line
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiLastName">Nachname*</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiLastName"
                  v-model="finApiWizard.lastName"
                  :rules="[notNullRule, ...lastNameRule]"
                  dense
                  hide-details="auto"
                  name="lastName"
                  outlined
                  single-line
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label
                  for="finApiDateOfBirth"
                >Geburtsdatum*</label>
              </v-col>
              <v-col cols="8">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  max-width="280px"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatDateGer"
                      :rules="birthDateRule"
                      dense
                      hide-details="auto"
                      outlined
                      readonly
                      single-line
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="finApiWizard.dateOfBirth"
                    no-title
                    scrollable
                    @input="menu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiGender">Geschlecht*</label>
              </v-col>
              <v-col cols="8">
                <v-select
                  id="finApiGender"
                  v-model="finApiWizard.gender"
                  :items="items"
                  :rules="[notNullRule]"
                  dense
                  flat
                  hide-details="auto"
                  item-text="label"
                  item-value="abbr"
                  name="gender"
                  outlined
                  solo
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiTitle">Titel</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiTitle"
                  v-model="finApiWizard.title"
                  dense
                  hide-details="auto"
                  name="title"
                  outlined
                  single-line
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiIban">IBAN*</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiIban"
                  v-model="finApiWizard.iban"
                  :rules="ibanRules"
                  dense
                  hide-details="auto"
                  name="iban"
                  outlined
                  single-line
                  @change="resetValidation"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label
                  for="finApiStreet"
                >Straße und Hausnummer*</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiStreet"
                  v-model="finApiWizard.street"
                  :rules="[notNullRule]"
                  dense
                  hide-details="auto"
                  name="street"
                  outlined
                  single-line
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiZip">Postleitzahl*</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiZip"
                  v-model="finApiWizard.zip"
                  :rules="[notNullRule]"
                  dense
                  hide-details="auto"
                  name="zip"
                  outlined
                  single-line
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiCity">Ort*</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiCity"
                  v-model="finApiWizard.city"
                  :rules="[notNullRule]"
                  dense
                  hide-details="auto"
                  name="city"
                  outlined
                  single-line
                />
              </v-col>
            </v-row>
            <v-row class="btn-row">
              <v-btn
                class="action-btn"
                :disabled="!valid"
                :loading="loadingSubmit"
                color="primary"
                type="submit"
                rounded
              >
                Online-Identifizierung starten
              </v-btn>
              <v-btn
                v-if="this.$store.state.boUser"
                class="action-btn"
                color="secondary"
                rounded
                @click="fillDemoData"
              >
                Mit Demodaten befüllen
              </v-btn>
            </v-row>

            <div class="text-center">
              <v-dialog
                v-model="dialog"
                eager
                persistent
                width="70%"
              >
                <v-card>
                  <div id="webFormContainer" />
                </v-card>
              </v-dialog>
            </div>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-container
            fill-height
            fluid
          >
            <v-row
              style="justify-content: center"
            >
              <Alert
                :type="verificationStatus.alertType"
                :title="verificationStatus.statusTitel"
                :text="verificationStatus.statusText"
              />
              <div
                v-if="verificationStatus.status === 'COMPLETED'"
                class="card-row"
              >
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                />
              </div>
            </v-row>
          </v-container>
          <v-container
            align-items="right"
            style="text-align: center"
          >
            <v-btn
              v-if="
                verificationStatus.status === 'IN_PROGRESS' ||
                  verificationStatus.status === 'NOT_STARTED'
              "
              :loading="loadingRefresh"
              class="action-btn mx-2"
              depressed
              large
              style="padding: 0 16px"
              rounded
              @click="refreshVerificationState"
            >
              <v-icon style="margin-right: 10px">
                $refresh
              </v-icon>
              Aktualisieren
            </v-btn>
            <v-btn
              v-else-if="
                verificationStatus.status === 'COMPLETED'
              "
              class="action-btn"
              color="primary"
              rounded
              @click="goToFiling"
            >
              Jetzt zur Übermittlung
            </v-btn>
            <v-btn
              v-else-if="verificationStatus.status === 'FAILURE'"
              class="action-btn"
              color="primary"
              rounded
              @click="resetWizard"
            >
              Daten prüfen
            </v-btn>
            <v-btn
              v-else-if="verificationStatus.status === 'ERROR'"
              class="action-btn"
              color="primary"
              rounded
              @click="resetWizard"
            >
              Erneut versuchen
            </v-btn>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-main>
</template>

<style lang="scss">
.v-stepper {
  margin: 20px 0 0;
}

#finApiVerification {
  box-shadow: $sui-shadow-main-container;
  border-radius: $sui-border-main-container;
}

#finApiStepperHeader {
  box-shadow: none !important;
}

.v-stepper__step__step {
  margin-right: 10px;
}

.formInfo {
    align-self: center;
}

a label {
    cursor: pointer;
    font-weight: bold;
    line-height: 27px;
}

.v-input {
    font-size: 13px;
    padding: 0;
}

.v-input input {
    padding: 0;
}

div.v-messages__message {
    white-space: pre-line;
}

.action-btn {
  margin: 25px 0 5px 0;
  box-shadow: none;
}

.btn-row {
  justify-content: center;
  gap: 10px;
}
</style>
