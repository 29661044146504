
import { Component, Prop, Vue } from 'vue-property-decorator'
import DownloadButton from '@/components/checkout/base/DownloadButton.vue'

@Component({
  components: { DownloadButton },
})
export default class DownloadDocuments extends Vue {
  @Prop({ default: () => [] }) private tokenName: any | undefined
  @Prop({ default: () => [] }) private csrfToken: any | undefined

  private pdfUrl = '/printouts/elsterPdf'
  private buttonText = 'Steuererklärung herunterladen'
}
