import PaymentOverview from '@/components/checkout/payment/PaymentOverview.vue'
import CheckoutWebComponent from '@/components/checkout/payment/CheckoutWebComponent.vue'
import CheckoutAppError from '@/components/checkout/CheckoutAppError.vue'
import UserVerificationMethods from '@/components/checkout/verification/UserVerificationMethods.vue'
import FilingTaxCase from '@/components/checkout/filing/FilingTaxCase.vue'
import FinApiWizardComponent from '@/components/checkout/verification/finApi/FinApiWizardComponent.vue'
import { Route } from 'vue-router'
import { store } from '@/vue-store'
import PaymentSuccess from '@/components/checkout/payment/PaymentSuccess.vue'
import FilingProcessingInfo from '@/components/checkout/filing/FilingProcessingInfo.vue'
import FilingElsterTickets from '@/components/checkout/filing/FilingElsterTickets.vue'
import WebIDVerification from '@/components/checkout/verification/WebID/WebIDVerification.vue'

export default [
  {
    path: '/checkout/overview',
    component: PaymentOverview,
    name: 'payment-overview',
    meta: {
      reload: true,
    },
    props: true, // Per-route Guards
    beforeEnter: (to: Route, from: Route, next: Function) => {
      store.state.showNavigationButtons = true
      const checkoutData = store.getters.getCheckoutData
      if (checkoutData.remainingFreeFilings > 0) {
        next({
          replace: true,
          name: 'verification-overview'
        })
      } else {
        if (checkoutData.productType === 'STUDENT' || checkoutData.priceIncludingTax === 0) {
          next({
            replace: true,
            name: 'payment-success'
          })
        } else {
          next()
        }
      }
    }
  },
  {
    path: '/checkout/payment',
    component: CheckoutWebComponent,
    name: 'checkout-web-component',
    meta: { reload: true },
    beforeEnter: (to: Route, from: Route, next: Function) => {
      store.state.showNavigationButtons = false
      const checkoutData = store.getters.getCheckoutData
      if (checkoutData.productType === 'STUDENT' || checkoutData.priceIncludingTax === 0 || checkoutData.remainingFreeFilings > 0) {
        next({
          replace: true,
          name: 'payment-success'
        })
      } else {
        next()
      }
    }
  },
  {
    path: '/checkout/payment/success',
    component: PaymentSuccess,
    name: 'payment-success',
    meta: { reload: true },
    beforeEnter: (to: Route, from: Route, next: Function) => {
      next({
        name: 'verification-overview'
      })
    }
  },
  {
    path: '/checkout/error',
    component: CheckoutAppError,
    name: 'checkout-error',
    meta: { reload: true },
  },
  {
    path: '/checkout/verification',
    component: UserVerificationMethods,
    name: 'verification-overview',
    meta: { reload: true },
    beforeEnter: (to: Route, from: Route, next: Function) => {
      store.state.showNavigationButtons = true

      const isAuth =
        store?.state?.verificationData?.isAuthenticated ?? false

      if (isAuth) {
        store.dispatch('updateCurrentStep', 'checkout-filing').then(r => next({
          replace: true,
          name: 'checkout-filing'
        }))
      } else {
        next()
      }
    }
  },
  {
    path: '/checkout/verification/webID',
    component: WebIDVerification,
    name: 'webId',
    meta: { reload: true },
    beforeEnter: (to: Route, from: Route, next: Function) => {
      store.state.showNavigationButtons = true
      next()
    }
  },
  {
    path: '/checkout/filing',
    component: FilingTaxCase,
    name: 'checkout-filing',
    meta: { reload: true },
    beforeEnter: (to: Route, from: Route, next: Function) => {
      store.state.showNavigationButtons = true
      next()
    }
  },
  {
    path: '/checkout/filing-info',
    component: FilingProcessingInfo,
    name: 'checkout-filing-info',
    meta: { reload: true },
    beforeEnter: (to: Route, from: Route, next: Function) => {
      store.state.showNavigationButtons = true
      next()
    }
  },
  {
    path: '/checkout/filing-transfer-data',
    component: FilingElsterTickets,
    name: 'checkout-filing-transfer-data',
    meta: { reload: true },
    beforeEnter: (to: Route, from: Route, next: Function) => {
      store.state.showNavigationButtons = true
      next()
    }
  },
  {
    path: '/checkout/verification/finApi',
    component: FinApiWizardComponent,
    name: 'finApi',
    meta: { reload: true },
    beforeEnter: (to: Route, from: Route, next: Function) => {
      store.state.showNavigationButtons = true
      next()
    }
  }
]
