
import { Component, Prop, Vue } from 'vue-property-decorator'
import NavigationButtons from '@/components/filing-preview/navigation/NavigationButtons.vue'
import DownloadButton from '@/components/checkout/base/DownloadButton.vue'
import Alert from '@/components/checkout/base/Alert.vue'
import axios from 'axios'

@Component({
  components: { NavigationButtons, DownloadButton, Alert }
})
export default class PreviewApp extends Vue {
  @Prop() private hasBusinessCase: any | undefined
  @Prop() private yuiSections: any | undefined
  @Prop() private csrfToken: any | undefined
  @Prop() private tokenName: any | undefined

  private loading = false
  private nextButtonId = 'ui-go-to-filing'
  private assessmentInfo: any = { firstPersonName: null, secondPersonName: null, separateAssessment: false }

  private personAButtonText = ''
  private personBButtonText = ''

  async created() {
    await this.getAssessmentInfo()
    if (this.assessmentInfo.separateAssessment) {
      this.personAButtonText = 'Vorschau für ' + this.assessmentInfo.firstPersonName
      this.personBButtonText = 'Vorschau für ' + this.assessmentInfo.secondPersonName
    }
  }

  async getAssessmentInfo() {
    this.loading = true
    try {
      const response = await axios.get('/api/state/getAssessmentInfo')
      this.assessmentInfo = response.data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Could not fetch assessment info', error)
    } finally {
      this.loading = false
    }
  }
}
