
import { Component, Prop, Vue } from 'vue-property-decorator'
import NavigationButtons from '@/components/filing-preview/navigation/NavigationButtons.vue'

@Component({
  components: { NavigationButtons }
})
export default class FilingIntroductionApp extends Vue {
  @Prop() private yuiSections: any | undefined

  private nextButtonId = 'ui-go-to-preview'
}
