


























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import DashboardServiceTile from '@/components/case-list/DashboardServiceTile.vue'
import Alert from '@/components/ui/Alert.vue'

@Component({ components: { Alert, DashboardServiceTile } })
export default class SidebarComponent extends Vue {
  @Prop() vastUrl!: string
  @Prop() beleguanUrl!: string
}
