





















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Tabs extends Vue {
  data() {
    return {
      tab: null
    }
  }

  get childTabs() {
    return this.$slots.default?.filter(tab => {
      return tab.componentOptions?.propsData?.hasOwnProperty('title')
    })
  }
}
