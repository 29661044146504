
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class FinApiInfo extends Vue {
  private showDialog = true

  showInfo(show: boolean) {
    this.showDialog = show
    this.$emit('show-info', show)
  }

  updated() {
    if (!this.showDialog) {
      this.showInfo(this.showDialog)
    }
  }
}
