
import { Vue, Component, Prop } from 'vue-property-decorator'
import Alert from '@/components/ui/Alert.vue'

@Component({
  components: { Alert }
})
export default class DashboardInfoDialog extends Vue {
  @Prop() private url!: string | undefined
  @Prop() private confirmButtonText!: string | undefined
  private showDialog = true

  showInfo(show: boolean) {
    this.showDialog = show
    this.$emit('dialog', show)
  }

  updated() {
    if (!this.showDialog) {
      this.showInfo(this.showDialog)
    }
  }
}
