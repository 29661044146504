










































import { Component, Inject, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { BackOfficeArea, BackOfficeRootArea } from '@/components/backoffice-area-tree/types'
import { VTreeViewStub } from '@/components/case-analysis/types'

@Component
export default class BackOfficeAreaTree extends Vue {
  @Prop() private rootElement: BackOfficeRootArea | undefined
  @Prop() private openAreaId: number | undefined
  @Inject() private eventBus!: Vue
  @Ref('tree') private tree!: VTreeViewStub

  private search = ''
  private open: BackOfficeArea[] = []

  get items(): object[] {
    const root = this.rootElement
    if (!root) {
      return []
    }
    return root.items
  }

  get allDirectlyTaggedAreas(): number[] {
    const root = this.rootElement
    if (!root) {
      return []
    }
    return root.directlyTaggedAreas
  }

  get allIndirectlyTaggedAreas(): number[] {
    const root = this.rootElement
    if (!root) {
      return []
    }
    return root.indirectlyTaggedAreas
  }

  isDirectlyTagged(item: BackOfficeArea): boolean {
    return this.allDirectlyTaggedAreas.includes(item.id)
  }

  isIndirectlyTagged(item: BackOfficeArea): boolean {
    return this.allIndirectlyTaggedAreas.includes(item.id)
  }

  handleActivate(items: BackOfficeArea[]) {
    if (items.length !== 1) return
    const item = items[0]
    this.eventBus.$emit('selectArea', { ...item })
    const idx = this.open.findIndex(value => value.id === item.id)
    if (idx === -1) {
      this.open = this.open.concat(item)
    }
  }

  @Watch('openAreaId', { immediate: true })
  onOpenAreaId(val: number | undefined, _: number | undefined) {
    if (val !== undefined) {
      const item = this.rootElement?.items?.find(value => value.id === val)
      if (item !== undefined) {
        this.open = this.open.concat(item)
      }
    }
  }
}
