
import { Component, Vue } from 'vue-property-decorator'
import FinApiWizardComponent from '@/components/checkout/verification/finApi/FinApiWizardComponent.vue'
import axios from 'axios'
import VerificationOK from '@/components/checkout/verification/VerificationOK.vue'
import { UserVerification } from '@/components/checkout/types'
import { mapActions } from 'vuex'
import icons from 'vuetify/src/services/icons/presets/fa'
import FinApiInfo from '@/components/checkout/verification/finApi/FinApiInfo.vue'
import WebIDVerification from '@/components/checkout/verification/WebID/WebIDVerification.vue'
import Alert from '@/components/checkout/base/Alert.vue'
import VerificationInfoDialog from '@/components/checkout/verification/VerificationDialog.vue'

@Component({
  methods: {
    icons() {
      return icons
    }
  },
  components: {
    FinApiWizardComponent,
    VerificationOK,
    FinApiInfo,
    WebIDVerification,
    Alert,
    VerificationInfoDialog,
  },
  computed: {
    ...mapActions({
      updatePaymentData: 'updatePaymentData'
    })
  }
})
export default class UserVerificationMethods extends Vue {
  private verification: any = { verificationMethod: 'UNKNOWN' }
  private showAuthenticationInfo = false
  private showFinApiInfo = false

  created() {
    this.updateVerificationData()
  }

  private async updateVerificationData() {
    const paymentData = this.$store.state.paymentData
    await axios.get('/api/checkout/verification-method').then(result => {
      this.verification = result.data
      const userVerification: UserVerification = {
        isVerified: false,
        verificationMethod: result.data.verificationMethod
      }
      if (userVerification.verificationMethod !== 'UNKNOWN') {
        userVerification.isVerified = true
      }
      this.verification.verificationMethod = userVerification.verificationMethod
      paymentData ? paymentData.verification = userVerification : paymentData
      this.$store.state.paymentData = paymentData
      this.$store.dispatch('updatePaymentData', paymentData)
    }).catch(errors => {
      console.log('Could not fetch verification data: ' + errors)
    })
  }

  updateFinApiInfoDialog(showInfo: boolean) {
    this.showFinApiInfo = showInfo
  }
}
