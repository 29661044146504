




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { GVError } from '@/components/case-analysis/types'
import Alert from '@/components/ui/Alert.vue'

@Component({
  components: { Alert }
})
export default class GVErrorComponent extends Vue {
      @Prop({ default: () => [] }) private gvErrors!: GVError[]
      @Prop({ default: true }) private smartCheckIsOk!: boolean
}
