
import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import Alert from '@/components/checkout/base/Alert.vue'

@Component({ components: { Alert } })
export default class DoNotSendReceipts extends Vue {
  created() {
    this.setDocumentsSent(true)
  }

  setDocumentsSent(sent: boolean) {
    axios.post('/elster/saveInterviewSessionParam?sent=' + (sent ? 'true' : 'false'), {}, {})
      // eslint-disable-next-line no-console
      .catch(errors => console.warn(errors))
  }
}
