
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class DownloadButton extends Vue {
  @Prop({ default: () => [] }) private pdfUrl: any | undefined

  @Prop({ default: () => [] }) private tokenName: any | undefined
  @Prop({ default: () => [] }) private csrfToken: any | undefined

  @Prop({ default: () => [] }) private buttonText: any | undefined
  private href = ''

  created() {
    const downloadUrl = new URL(window.location.origin + this.pdfUrl)
    downloadUrl.searchParams.set(this.tokenName, this.csrfToken)
    this.href = downloadUrl.href
  }
}
