var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"authentication-overview"}},[_c('v-card-title',{staticClass:"text-h1 font-weight-bold"},[_vm._v("\n    Online-Identifizierung\n  ")]),_vm._v(" "),(_vm.verification.verificationMethod !== 'UNKNOWN')?_c('VerificationOK',{attrs:{"verification-method":_vm.verification.verificationMethod}}):(_vm.$store.state.verificationData.chosenVerificationMethod === 'finApi' && _vm.$store.state.currentStep === 'finApi')?_c('FinApiWizardComponent',{attrs:{"reload-url":_vm.$store.state.yuiSections.current}}):(_vm.$store.state.verificationData.chosenVerificationMethod === 'webId' && _vm.$store.state.currentStep === 'webId')?_c('WebIDVerification'):_c('v-sheet',{attrs:{"id":"authentication-methods"}},[_c('v-container',{staticClass:"card-text-container",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"card-col",attrs:{"cols":"12","md":"2"}},[_c('v-icon',{attrs:{"color":"primary","x-large":""}},[_vm._v("\n            fa fa-id-card\n          ")])],1),_vm._v(" "),_c('v-col',{staticClass:"card-col",attrs:{"cols":"12","md":"10"}},[_c('p',{staticClass:"mb-4"},[_vm._v("\n            Wir sind verpflichtet, Sie vor der Übermittlung der\n            Steuererklärung einmalig zu identifizieren. Bitte\n            benutzen Sie hierzu den Service von finAPI oder\n            WebID."),_c('br'),_vm._v(" "),_c('a',{attrs:{"id":"authentication-info","href":"javascript:void(0);"},on:{"click":function($event){_vm.showAuthenticationInfo = true}}},[_vm._v("Warum muss ich mich identifizieren?")]),_vm._v(" "),_c('v-dialog',{attrs:{"id":"verification-info-dialog","width":"50%"},model:{value:(_vm.showAuthenticationInfo),callback:function ($$v) {_vm.showAuthenticationInfo=$$v},expression:"showAuthenticationInfo"}},[_c('VerificationInfoDialog')],1)],1)])],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"card-text-container"},[_c('v-radio-group',{staticClass:"radio-btn",model:{value:(
          _vm.$store.state.verificationData.chosenVerificationMethod
        ),callback:function ($$v) {_vm.$set(_vm.$store.state.verificationData, "chosenVerificationMethod", $$v)},expression:"\n          $store.state.verificationData.chosenVerificationMethod\n        "}},[_c('v-row',{attrs:{"id":"verification-method-input","align":"stretch","justify":"space-around"}},[_c('v-col',{attrs:{"cols":"2"}}),_vm._v(" "),(
              _vm.$store.state.verificationData.verificationMethods[0].enabled
            )?_c('v-col',{attrs:{"align-self":"center","cols":"3"}},[_c('label',[_c('v-img',{attrs:{"id":"finApi-img","src":"/../img/verification/finapi-logo.png","alt":"finApi","contain":""}}),_vm._v(" "),_c('v-radio',{attrs:{"id":"finApi","value":"finApi"}},[_c('v-btn',{attrs:{"outlined":""}})],1)],1)]):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"cols":"2"}}),_vm._v(" "),_c('v-col',{attrs:{"cols":"3","align-self":"center"}},[_c('label',[_c('v-img',{attrs:{"id":"webid-img","src":"/../img/verification/WebID_Logo_ohne_Claim.png","alt":"webId","contain":""}}),_vm._v(" "),_c('v-radio',{attrs:{"id":"webID","value":"webId"}},[_c('v-btn',{attrs:{"outlined":""}})],1)],1)]),_vm._v(" "),_c('v-col',{attrs:{"cols":"2"}})],1)],1),_vm._v(" "),(
          _vm.$store.state.verificationData.chosenVerificationMethod ===
            _vm.$store.state.verificationData.verificationMethods[0]
              .name &&
            _vm.$store.state.verificationData.verificationMethods[0]
              .enabled
        )?_c('v-card',{staticClass:"bubble finApi",attrs:{"id":"finAPI-description","color":"white","outlined":"","rounded":"xl"}},[_c('v-card-title',{staticClass:"card-title"},[_vm._v("\n          finAPI\n        ")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-10"}),_vm._v(" "),_c('v-card-subtitle',{staticClass:"card-subtitle"},[_vm._v("\n          sicher via Online-Banking\n        ")]),_vm._v(" "),_c('v-card-text',{staticClass:"card-text"},[_vm._v("\n          Sie loggen sich auf einer gesicherten Seite von finAPI\n          (BaFin-lizenziert) in Ihr Online-Banking ein. finAPI\n          erhält einen einmaligen Zugriff auf Ihre Kontodaten, um\n          Sie für smartsteuer sicher zu identifizieren."),_c('br'),_vm._v(" "),_c('a',{attrs:{"id":"ui-finApi-info","href":"javascript:void(0);"},on:{"click":function($event){_vm.showFinApiInfo = true}}},[_c('b',[_vm._v("Mehr erfahren.")])]),_vm._v(" "),(_vm.showFinApiInfo)?_c('FinApiInfo',{on:{"show-info":_vm.updateFinApiInfoDialog}}):_vm._e()],1)],1):_c('v-card',{staticClass:"bubble webId",attrs:{"id":"webID-description","color":"white","outlined":"","rounded":"true"}},[_c('v-card-title',{staticClass:"card-title"},[_vm._v("\n          WebID\n        ")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-1"}),_vm._v(" "),_c('v-card-text',{staticClass:"card-text"},[_vm._v("\n          WebID ist Erfinder und Pionier der\n          Geldwäschegesetz-konformen Video-Identifikation. Sie\n          benötigen eine Webcam oder ein Smartphone und\n          identifizieren sich mit Fotos von Ausweis und Gesicht.\n          "),_c('p',[_c('b',[_vm._v("Nur mit gültigem deutschen Personalausweis oder\n              Reisepass möglich.\n            ")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }