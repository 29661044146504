






















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Button extends Vue {
  @Prop() private text!: string;
  @Prop({ type: Boolean }) private primary!: boolean;
  @Prop({ type: Boolean }) private small!: boolean;
  @Prop() private icon!: string;
  @Prop() private iconPos!: string;

  data() {
    return {
      classes: [
        this.primary ? 'primary' : 'secondary',
        this.small ? '' : 'v-size--large'
      ]
    }
  }
}
