




import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  CaseAnalysisPreviewContent,
  CaseAnalysisPreviewProps
} from '@/components/case-analysis/types'

@Component
export default class PreviewWrapperComponent extends Vue {
    @Prop() yuiProps: CaseAnalysisPreviewProps | undefined
    @Prop() content: CaseAnalysisPreviewContent | undefined

    mounted() {
      if (this.yuiProps) {
        this.yuiProps.mounted(this.$el)
        this.onContentChanged(this.content)
      }
    }

    @Watch('content')
    onContentChanged(val: CaseAnalysisPreviewContent | undefined) {
      if (!val || (val.report == null && val.sheet == null)) {
        return
      }
      if (this.yuiProps) {
        this.yuiProps.show(val)
      }
    }
}
