
import { Component, Vue } from 'vue-property-decorator'
import CheckoutAppHeader from '@/components/checkout/CheckoutAppHeader.vue'
import LoadingComponent from '@/components/checkout/base/LoadingComponent.vue'
import CheckoutAppError from '@/components/checkout/CheckoutAppError.vue'
import PaymentSuccess from '@/components/checkout/payment/PaymentSuccess.vue'

@Component({
  components: { LoadingComponent, CheckoutAppHeader, CheckoutAppError, PaymentSuccess }
})
export default class PaymentOverview extends Vue {
  private percentFormat!: Intl.NumberFormat;
  private currencyFormat!: Intl.NumberFormat;

  created() {
    this.currencyFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })
    this.percentFormat = new Intl.NumberFormat('de-DE', { style: 'percent' })
  }
}
