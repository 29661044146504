import { Component, Prop, Watch, Vue } from 'vue-property-decorator'

@Component
export default class PromiseFrame<T> extends Vue {
  @Prop() private promise!: Promise<T>

  private response: T | null = null
  private pending = true
  private error: Error | null = null

  @Watch('promise', { immediate: true })
  async onPromiseChanged(promise?: Promise<T>) {
    if (!promise) {
      return
    }
    try {
      this.response = await promise
    } catch (e) {
      this.error = e
      this.response = null
    } finally {
      this.pending = false
    }
  };

  render() {
    // Render the default scoped slot and
    // provide data and method properties
    // via the slot scope.
    const slot = this.$scopedSlots?.default
    if (slot) {
      const props = {
        response: this.response,
        error: this.error,
        pending: this.pending
      }
      return slot(props)
    }
  }
}
