
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { AlertVariant } from '@/components/ui/types'

@Component
export default class Alert extends Vue {
  @Prop() variant!: AlertVariant

  // expose type to template
  AlertVariant = AlertVariant

  get variantClass() {
    return 'alert-variant-' + this.variant
  }
}

