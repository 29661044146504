
import { Component, Vue } from 'vue-property-decorator'
import { mapActions } from 'vuex'

@Component({})
export default class CheckoutWebComponent extends Vue {
  private webComponentUri: string | undefined

  private checkoutComponentEvent: any = {
    oldIndex: 0,
    newIndex: 0,
    leave: false,
    name: 'customer-editor'
  }

  methods() {
    mapActions([
      'emitCheckoutComponentEvent',
      'updateCurrentStep',
      'updateCurrentFilingStepIndex'
    ])
  }

  created() {
    this.$store.dispatch(
      'updateLoadingErrorStatus',
      { status: false, message: '' }
    )
    this.bindCheckoutWebComponent()
    this.$store.dispatch(
      'emitCheckoutComponentEvent',
      this.checkoutComponentEvent
    )
  }

  mounted() {
    this.addScriptEvent()
    this.addEventListenerToCheckoutComponent()
  }

  buildCheckoutWebComponentUri() {
    return (
      this.$store.state.checkoutServiceBaseUri +
      '/js/checkout-web-component.js'
    )
  }

  bindCheckoutWebComponent() {
    this.webComponentUri = this.buildCheckoutWebComponentUri()
    const checkoutWebComponent = document.createElement('script')
    checkoutWebComponent.setAttribute('src', this.webComponentUri)
    checkoutWebComponent.setAttribute('type', 'modules')
    document.head.appendChild(checkoutWebComponent)
  }

  addEventListenerToCheckoutComponent() {
    const checkoutComponent = document.getElementById('checkoutWebComponent')

    if (checkoutComponent) {
      checkoutComponent.addEventListener(
        'checkout-step-changed',
        this.handleChangeCheckoutStep
      )
    }
  }

  handleChangeCheckoutStep(event: any) {
    const eventTargetContainer = document.getElementById(
      'checkout-component-event'
    )
    if (eventTargetContainer) {
      eventTargetContainer.innerText = JSON.stringify(event?.detail)
    }

    this.checkoutComponentEvent = event?.detail
    this.emitCheckoutEvent()
    this.handleCheckoutComponentEvent()
  }

  handleCheckoutComponentEvent() {
    if (this.checkoutComponentEvent !== null && this.checkoutComponentEvent.leave) {
      if (this.checkoutComponentEvent.name === 'finished-checkout') {
        this.$store.dispatch(
          'updateCurrentStep',
          'verification-overview'
        )
        this.$store.dispatch(
          'updateCurrentFilingStepIndex',
          this.checkoutComponentEvent.newIndex + 2
        ).then(() => {
          this.checkoutComponentEvent = null
          this.$store.dispatch('emitCheckoutComponentEvent', null)
          this.$router.replace({ name: 'verification-overview' })
        })
      }
      if (this.checkoutComponentEvent?.name === 'left-checkout') {
        this.checkoutComponentEvent = null
        this.$store.dispatch('emitCheckoutComponentEvent', null)
        this.$store.dispatch('updateCurrentStep', 'payment-overview')
        this.$store.dispatch('updateCurrentFilingStepIndex', 0)
        this.$router.replace({ name: 'payment-overview' })
      }
    } else {
      if (this.$store.state.currentStep === 'checkout-web-component') {
        const filingStepIndex = this.checkoutComponentEvent.newIndex + 1
        this.$store.dispatch(
          'updateCurrentFilingStepIndex',
          filingStepIndex
        )
      } else {
        this.$store.dispatch(
          'updateCurrentFilingStepIndex',
          this.$store.state.currentFilingStepIndex + 1
        )
      }
    }
  }

  addScriptEvent() {
    const checkoutComponentScript = document.getElementById('checkout-web-component-script')
    checkoutComponentScript?.addEventListener('error', (event) => {
      this.$store.dispatch(
        'updateLoadingErrorStatus',
        { status: true, message: 'Der Bezahlvorgang kann nicht gestartet werden.' }
      )
      this.$router.replace({ name: 'checkout-error' })
    })
  }

  emitCheckoutEvent() {
    this.$store.dispatch(
      'emitCheckoutComponentEvent',
      this.checkoutComponentEvent
    )
  }
}
